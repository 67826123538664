<template>
  <component :is="commonComponent" id="blog" title="Blogs">
    <section id="blog-content" class="pc_padding">
      <h1 class="m-b-30 head-1">
        How to Create a Daily Self-Discipline Plan on a Single Sheet of Paper: 7 Daily Planning
        Methods
      </h1>

      <div class="autor m-b-30">
        <div>Author: Folg </div>
        <div>Publish: 2024/8/19 </div>
      </div>
      <div class="slish m-b-20" />

      <p>
        The 24 hours in a day will pass regardless, but the difference between people lies in their
        attitude towards time.
      </p>

      <p>How you spend a day reflects how you spend a year.</p>

      <p>
        Here are seven daily planning methods I&#39;ve used, suitable for different needs. You can
        print them out on an A4 sheet.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Four Quadrants Method
      </h2>

      <ul class="in-page-ul m-t-40 m-b-20">
        <li>
          <p>
            <strong>Design Logic</strong>: Based on the time management method proposed by American
            management expert Stephen Covey. For further reading, check out &quot;The 7 Habits of
            Highly Effective People&quot;.
          </p>
        </li>
        <li>
          <p>
            <strong>Suitable For</strong>: Those who struggle to prioritize tasks and have many
            miscellaneous tasks.
          </p>
        </li>
      </ul>
      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/THE-QUADRANT-SYSTEM-1-1024x1024.png">
      </p>
      <h2 class="head-2 m-t-60 m-b-30">
        Time Tracking Method
      </h2>

      <ul class="in-page-ul m-t-40 m-b-20">
        <li>
          <p>
            <strong>Design Logic</strong>: Based on the time tracking method by L. V. Lyubishchev.
            For more details, refer to &quot;How to Live on 24 Hours a Day&quot;.
          </p>
        </li>
        <li>
          <p>
            <strong>Suitable For</strong>: Those who are highly efficient and aim to maximize time
            utilization.
          </p>
        </li>
      </ul>
      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/64cbb2a8abe9108e439404bd_planning.webp">
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Categorized List Method
      </h2>

      <ul class="in-page-ul m-t-40 m-b-20">
        <li>
          <p>
            <strong>Design Logic</strong>: Inspired by &quot;Eat That Frog!&quot;, which suggests
            tackling the three most important tasks each day.
          </p>
        </li>
        <li>
          <p><strong>Suitable For</strong>: Those with a complex mix of work and life tasks.</p>
        </li>
      </ul>

      <h2 class="head-2 m-t-60 m-b-30">
        PDCA Cycle
      </h2>

      <ul class="in-page-ul m-t-40 m-b-20">
        <li>
          <p>
            <strong>Design Logic</strong>: Based on the PDCA (Plan-Do-Check-Act) cycle by Deming.
          </p>
        </li>
        <li>
          <p><strong>Suitable For</strong>: Professionals and project managers.</p>
        </li>
      </ul>
      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/The-PDCA-Cycle-for-continuous-improvement-1.png">
      </p>
      <h2 class="head-2 m-t-60 m-b-30">
        Pomodoro Technique
      </h2>

      <ul class="in-page-ul m-t-40 m-b-20">
        <li>
          <p>
            <strong>Design Logic</strong>: From &quot;The Pomodoro Technique&quot;, which helps
            improve focus and reduce procrastination.
          </p>
        </li>
        <li>
          <p>
            <strong>Suitable For</strong>: Those with low focus, poor execution, or children
            learning to develop habits.
          </p>
        </li>
      </ul>
      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/f3c3cf_46c7bf4679084d54b5896a4f46da7404~mv2.webp">
      </p>
      <h2 class="head-2 m-t-60 m-b-30">
        The Ivy Lee Method
      </h2>

      <ul class="in-page-ul m-t-40 m-b-20">
        <li>
          <p>
            <strong>Design Logic</strong>: Based on the Ivy Lee method of time management—list the
            six most important tasks to complete each day, prioritize them, and start with the first
            task in the morning.
          </p>
        </li>
        <li>
          <p><strong>Suitable For</strong>: Most people, especially those seeking simplicity.</p>
        </li>
      </ul>
      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/63ec01d05542613c905804a7_Ivy Lee method 1.png">
      </p>
      <h2 class="head-2 m-t-60 m-b-30">
        Time Blocking Method
      </h2>

      <ul class="in-page-ul m-t-40 m-b-20">
        <li>
          <p>
            <strong>Design Logic</strong>: Divide the day into three parts: morning, afternoon, and
            evening.
          </p>
        </li>
        <li>
          <p><strong>Suitable For</strong>: Students or those with a fixed schedule.</p>
        </li>
      </ul>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/time-blocking.jpg">
      </p>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';
import '@/css/blog_base.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'How to Create a Daily Self-Discipline Plan on a Single Sheet of Paper: 7 Daily Planning Methods',
      meta: [
        {
          name: 'description',
          content: 'This guide introduces seven effective methods for creating a daily self-discipline plan, each tailored to different needs and preferences. ',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>
